import React, { useState, useEffect } from 'react';
import { Box, Typography, Divider, IconButton, Skeleton } from '@mui/material';
import WidgetsIcon from '@mui/icons-material/Widgets';
import { fetchInventoryAggregatesV2, fetchSalesAggregatesV2 } from '../api/inventoryV2';
import trinity_logo from '../assets/logo.png';
import { format } from 'date-fns';


const MobilePage = () => {
  const [inventoryData, setInventoryData] = useState([]);
  const [salesData, setSalesData] = useState([]);
  const [toggleInventoryView, setToggleInventoryView] = useState(true);
  const [loadingInventoryData, setLoadingInventoryData] = useState(false);
  const [loadingSalesData, setLoadingSalesData] = useState(false);

  useEffect(() => {
    const fetchInventoryData = async () => {
      setLoadingInventoryData(true);
      const data = await fetchInventoryAggregatesV2();
      setInventoryData(data);
      setLoadingInventoryData(false);
    };

    const fetchSalesData = async () => {
      setLoadingSalesData(true);
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const data = await fetchSalesAggregatesV2(userTimeZone);
      setSalesData(data);
      setLoadingSalesData(false);
    };

    fetchInventoryData();
    fetchSalesData();
  }, []);

  const handleToggleInventoryView = async () => {
    setLoadingInventoryData(true);
    const data = await fetchInventoryAggregatesV2(toggleInventoryView);
    setToggleInventoryView(!toggleInventoryView);
    setInventoryData(data);
    setLoadingInventoryData(false);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        bgcolor: '#ebebeb',
        height: '100vh',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'top',
          justifyContent: 'center',
          bgcolor: 'grey.800',
          p: 1,
          mx: 2,
          mt: 1,
          borderRadius: '10px',
        }}
      >
        <img src={trinity_logo} alt="Trinity Logo" style={{ width: '100px' }} />
      </Box>

      <Box
        sx={{
          flex: '1',
          display: 'flex',
          flexDirection: 'column',
          bgcolor: '#ebebeb',
        }}
      >

        <Box
          sx={{
            flex: '1',
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'top',
            justifyContent: 'left',
            borderRadius: '10px',
            border: '1px solid #ccc',
            mx: 2,
            my: 2,
            p: 3,
            overflow: 'scroll'
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="body1" color="grey.800" fontFamily={'MontHeavy'} component={'div'}>
              Inventory
              <IconButton color="inherit" onClick={handleToggleInventoryView}>
                <WidgetsIcon fontSize='small' />
              </IconButton>
            </Typography>

            <Typography variant="body2" color="grey.800">
              {inventoryData && inventoryData.reduce((total, item) => total + Number(item.qty), 0).toLocaleString()} units
            </Typography>
          </Box>
          <Divider sx={{ my: 0.5, borderColor: 'grey.700' }} flexItem />
          {loadingInventoryData ? (
            <>
              <Skeleton variant="text" height={20} />
              <Skeleton variant="text" height={20} />
              <Skeleton variant="text" height={20} />
              <Skeleton variant="text" height={20} />
            </>
          ) : !toggleInventoryView ? (
            inventoryData &&
            Object.entries(
              inventoryData.reduce((acc, item) => {
                if (!acc[item.name]) {
                  acc[item.name] = [];
                }
                acc[item.name].push(item);
                return acc;
              }, {})
            ).map(([name, items]) => (
              <div key={name} style={{ marginBottom: '16px' }}>
                <Typography variant="body2" color="grey.900">
                  <b>{name}</b>
                </Typography>
                {items.map((item, index) => (
                  <Typography key={index} variant="body2" color="grey.800" style={{ paddingLeft: '16px' }}>
                    {item.item ? <span>{item.item}</span> : ''}: {Number(item.qty).toLocaleString()} units
                  </Typography>
                ))}
              </div>
            ))
          ) : (
            inventoryData &&
            inventoryData.map((item, index) => (
              <Typography key={index} variant="body2" color="grey.800">
                <b>{item.name}</b> {item.item ? <span style={{ padding: '0 3px' }}>[{item.item}]</span> : ''}: {Number(item.qty).toLocaleString()} units
              </Typography>
            ))
          )}

        </Box>

        <Box
          sx={{
            flex: '1',
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'top',
            justifyContent: 'left',
            borderRadius: '10px',
            border: '1px solid #ccc',
            mx: 2,
            mb: 2,
            p: 3,
            overflow: 'scroll'
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="body1" color="grey.800" fontFamily={'MontHeavy'}>
              Sales
            </Typography>

            <Typography variant="body2" color="grey.800">
              Sales Today: ${salesData &&
                salesData
                  .reduce((total, item) => total + Number(item.total_amount), 0)
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </Typography>
          </Box>
          <Divider sx={{ my: 0.5, borderColor: 'grey.700' }} flexItem />
          {loadingSalesData ?
            (
              <>
                <Skeleton variant="text" height={20} />
                <Skeleton variant="text" height={20} />
                <Skeleton variant="text" height={20} />
                <Skeleton variant="text" height={20} />
              </>
            ) : (
              salesData && salesData.map((item, index) => (
                <React.Fragment key={index}>
                  <Typography variant="body2" color="grey.800">
                    <b>{item.source}: </b>
                    {Number(item.order_count).toLocaleString()} units,
                    AOV: ${Number(item.average_amount).toFixed(2).toLocaleString()},
                    ${Number(item.total_amount).toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </Typography>
                  <Typography variant="body2" color="grey.800" align="left" style={{ fontSize: '0.75rem' }}>
                    Updated: {format(new Date(item.last_updated), 'yyyy-MM-dd hh:mm:ss a')}
                  </Typography>
                </React.Fragment>
              ))
            )}
        </Box>
      </Box>
    </Box>
  );
};

export default MobilePage;
