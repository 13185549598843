import React from 'react';
import {
  Box,
  IconButton,
} from '@mui/material';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { updateOrderDetailV2, fetchDocumentsV2 } from '../../api/ordersV2.js'
import ToggleOrdersViewV2 from '../../components/ToggleOrdersViewV2.js';
import DocumentsPopover from '../../components/DocumentsPopover';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';

export default function OrderDetailsV2({
  rows,
  loading,
  selectedSubView,
  setSelectedSubView,
  height = '83vh'
}) {
  const [docsAnchorEl, setDocsAnchorEl] = React.useState(null);
  const [pinnedDocuments, setPinnedDocuments] = React.useState([]);

  const handleRowUpdate = async (newRow, oldRow) => {
    await updateOrderDetailV2(newRow.id, newRow);
    return newRow;
  };

  const handleFetchPinnedDocuments = async (event, row) => {
    const pinned_docs = await fetchDocumentsV2(null, null, null, null, null, row.id);
    setPinnedDocuments(pinned_docs);
    setDocsAnchorEl(event.target)
  };

  const Toolbar = () => {
    return (
      <GridToolbarContainer >
        <ToggleOrdersViewV2
          currentSubView={selectedSubView}
          setCurrentSubView={setSelectedSubView}
        />
      </GridToolbarContainer>
    );
  };

  const columns = [
    { field: 'partner_name', headerName: 'From', flex: 1.25, headerClassName: 'super-app-theme--header-new' },
    { field: 'partner_name_to', headerName: 'To', flex: 1.25, headerClassName: 'super-app-theme--header-new' },
    { field: 'order_name', headerName: 'Order', flex: 1.5, headerClassName: 'super-app-theme--header-new' },
    { field: 'status', headerName: 'Status', flex: 1, headerClassName: 'super-app-theme--header-new' },
    {
      field: 'item_name',
      headerName: 'Item',
      flex: 2.5,
      headerClassName: 'super-app-theme--header-new',
      editable: true
    },
    {
      field: 'invoice_qty',
      headerName: 'Qty',
      flex: 0.75,
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      editable: true,
      headerClassName: 'super-app-theme--header-new',
      renderCell: (params) => params.value ? new Intl.NumberFormat('en-US').format(params.value) : new Intl.NumberFormat('en-US').format(params.row.order_qty)
    },
    {
      field: 'item_unit_of_measure',
      headerName: 'UoM',
      flex: 1,
      headerClassName: 'super-app-theme--header-new',
      editable: true
    },
    {
      field: 'item_price',
      headerName: 'Unit Price',
      flex: 1,
      headerClassName: 'super-app-theme--header-new',
      editable: true,
      renderCell: (params) => params.value ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(params.value) : ''
    },
    {
      field: 'lot_number',
      headerName: 'Lot',
      flex: 1.5,
      headerClassName: 'super-app-theme--header-new',
      editable: true
    },
    {
      field: 'expiration_date_raw',
      headerName: 'Exp. Date',
      width: 100,
      headerClassName: 'super-app-theme--header-new',
      editable: true
    },
    {
      field: 'order_date', headerName: 'Order Date', width: 100, headerClassName: 'super-app-theme--header-new',
      renderCell: (params) => {
        if (!params.value) {
          return '';
        }
        const date = new Date(params.value);
        return isNaN(date.getTime()) ? '' : date.toISOString().slice(0, 10);
      }
    },
    {
      field: 'documents_count',
      headerName: 'Documents',
      headerAlign: 'center',
      align: 'center',
      width: 100,
      headerClassName: 'super-app-theme--header-new',
      renderCell: (params) => params.value > 0 ? (
        <>
          <IconButton
            onClick={(e) => handleFetchPinnedDocuments(e, params.row)}
          >
            <DocumentScannerIcon color='info' />
          </IconButton>
          <DocumentsPopover
            anchorEl={docsAnchorEl}
            setAnchorEl={setDocsAnchorEl}
            selectedDocuments={pinnedDocuments}
          />
        </>
      ) : null
    },
  ];

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      bgcolor: 'white',
      height: height,
      maxHeight: height,
      width: '100%',
      borderRadius: '10px',
    }}>
      <Box sx={{ width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          hideFooter
          density="compact"
          loading={loading}
          processRowUpdate={handleRowUpdate}
          slots={{ toolbar: Toolbar }}
          disableRowSelectionOnClick
        />
      </Box>
    </Box>
  );
}
