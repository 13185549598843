import React, { useEffect } from 'react';
import { Dialog, Typography, Box, Button, IconButton, TextField, Tooltip } from '@mui/material';
import ToggleDetailsView from '../components/ToggleDetailsView';
import { fetchOrders } from '../api/orders';
import { fetchInventory, fetchMaterialsInventory, fetchDeliveredProducts, fetchDeliveredMaterials, fetchMaterialsInRecipes } from '../api/products';
import { fetchFinancials } from '../api/financials';
import { createIntegrationShare, fetchIntegrationShare, deleteIntegrationShare } from '../api/sharing';
import OrdersDialogDatagrid from './datagrid/OrdersDialogDatagrid';
import MaterialsInventoryDialogDatagrid from './datagrid/MaterialsInventoryDialogDatagrid'
import MaterialsDialogDatagrid from './datagrid/MaterialsDialogDatagrid'
import InventoryDialogDatagrid from './datagrid/InventoryDialogDatagrid'
import ShipmentsDatagrid from './datagrid/ShipmentsDatagrid';
import FinancialsDatagrid from './datagrid/FinancialsDatagrid'
import EmailLogsDatagrid from './datagrid/EmailLogsDatagrid'
import ProductsDialogDatagrid from './datagrid/ProductsDialogDatagrid'
import DocumentDatagrid from './datagrid/DocumentDatagrid'
import PlaceIcon from '@mui/icons-material/Place';
import EmailIcon from '@mui/icons-material/Email';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import HelpIcon from '@mui/icons-material/Help';
import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from '@mui/icons-material/Search';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ReceiptIcon from '@mui/icons-material/Receipt';
import {
  updateIntegration,
  testTikTokEndpoint,
  testAirtableEndpoint,
  testWooCommerceEndpoint,
  testQuickbooksEndpoint,
  testsSheetsEndpoint
} from '../api/integrations';
import { fetchDocuments, fetchEmailLogs } from '../api/documents';
import { fetchShipments } from '../api/shipments';
import { axiosInstance, checkToken } from '../api/utils';
import shopify_logo from '../assets/icons8-shopify-48.png';
import trinity_logo from '../assets/favicon.ico';
import airtable_logo from '../assets/airtable.png';
import tiktok_logo from '../assets/icons8-tik-tok-50.png';
import woocommerce_logo from '../assets/woocommerce_logo.png';
import quickbooks_logo from '../assets/icons8-quickbooks-32.png';
import sheets_logo from '../assets/icons8-google-sheets-48.png';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { format } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';


const IntegrationDialogView = ({
  open,
  productsRows,
  setProductsRows,
  materialsRows,
  setMaterialsRows,
  logoBlob,
  integration,
  integrations,
  setIntegrations,
  onClose,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarAlert,
  dataSources
}) => {
  const [rows, setRows] = React.useState([]);
  const [isEditing, setIsEditing] = React.useState(false);
  const [integrationEditor, setIntegrationEditor] = React.useState({});
  const [locationSearch, setLocationSearch] = React.useState('');
  const [ordersToRows, setOrdersToRows] = React.useState([]);
  const [deliveredIds, setDeliveredIds] = React.useState([]);
  const [materialsRecipesIds, setMaterialsRecipesIds] = React.useState([]);
  const [inventoryRows, setInventoryRows] = React.useState([]);
  const [materialInventoryRows, setMaterialInventoryRows] = React.useState([]);
  const [documentRows, setDocumentRows] = React.useState([]);
  const [emailLogRows, setEmailLogRows] = React.useState([]);
  const [financialsRows, setFinancialsRows] = React.useState([]);
  const [tableView, setTableView] = React.useState('table');
  const [currentView, setCurrentView] = React.useState(
    integration.integration_type.category === '3PL' ? 'inventory' : (integration.integration_type.type === 'HQ' ? 'products' : 'orders')
  );
  const orderType = integration.integration_type.type === 'Supplier' ? 'material' : integration.integration_type.type === 'Manufacturer' ? 'product' : null;
  const orderToType = integration.integration_type.type === 'Manufacturer' ? 'material' : integration.integration_type.category === '3PL' ? 'product' : null;
  const hqIntegrationId = integrations.find(integration => integration.integration_type.type === 'HQ')?.id || null;
  const [loading, setLoading] = React.useState(false);
  const [addingShare, setAddingShare] = React.useState(false);
  const [shareWith, setShareWith] = React.useState("");
  const [sharedWithList, setShareWithList] = React.useState([]);
  const [initializedSharedWith, setInitializedSharedWith] = React.useState(false);


  const handleClose = () => {
    setRows([]);
    onClose();
  };

  const handleSave = async () => {
    try {
      const typSafeIntegrationEditor = {
        ...integrationEditor,
        minimum_order_qty: integrationEditor.minimum_order_qty === '' ? null : integrationEditor.minimum_order_qty,
        default_lead_time: integrationEditor.default_lead_time === '' ? null : integrationEditor.default_lead_time,
      };

      const data = await updateIntegration(integration.id, typSafeIntegrationEditor);
      setIntegrations((prevIntegrations) =>
        prevIntegrations.map((int) =>
          int.id === integration.id ? data : int
        )
      );
      setSnackbarAlert("success")
      setSnackbarMessage("Partner updated.")
      setSnackbarOpen(true)
      setIsEditing(false);
    } catch (error) {
      console.error('Error saving partner:', error);
      setSnackbarOpen(true);
      setSnackbarMessage(error.response.data.detail);
      setSnackbarAlert("error");
    }
  };

  const handleStartEditing = () => {
    setIntegrationEditor(integration);
    setIsEditing(true);
  };

  // const handleDeleteClick = async (id) => {
  //   try {
  //     await deleteIntegration(id);
  //     setIntegrations((prevIntegrations) => prevIntegrations.filter(integration => integration.id !== id));
  //     setRows([]);
  //     onClose();
  //   } catch (error) {
  //     setSnackbarOpen(true);
  //     setSnackbarMessage(error.response.data.detail);
  //     setSnackbarAlert("error");
  //   }
  // };

  const handleLocationLookup = async () => {
    try {
      const token = await checkToken();
      const response = await axiosInstance(token).post('/location_lookup/', { location: locationSearch });
      setIntegrationEditor({
        ...integrationEditor,
        street_address: response.data.body[0].formatted_address,
        billing_address: response.data.body[0].formatted_address,
        coordinates: {
          ...integrationEditor.coordinates,
          lat: response.data.body[0].geometry.location.lat,
          long: response.data.body[0].geometry.location.lng
        }
      });
    } catch (error) {
      console.error('Error fetching location data:', error);
    }
  };


  const handleKeyPress = async (e) => {
    if (e.key === 'Enter') {
      await handleLocationLookup();
    }
  };


  useEffect(() => {
    if (currentView === 'products') {
      const fetchDeliveredData = async () => {
        try {
          setLoading(true);
          const data = await fetchDeliveredProducts();
          setDeliveredIds(data);
        } catch (error) {
          console.error('Error fetching orders:', error);
        } finally {
          setLoading(false);
        }
      };
      fetchDeliveredData();
    } else if (currentView === 'materials') {
      const fetchDeliveredData = async () => {
        try {
          setLoading(true);
          const data = await fetchDeliveredMaterials();
          setDeliveredIds(data);
        } catch (error) {
          console.error('Error fetching orders:', error);
        } finally {
          setLoading(false);
        }
      };
      const fetchMaterialsRecipesData = async () => {
        try {
          setLoading(true);
          const data = await fetchMaterialsInRecipes();
          setMaterialsRecipesIds(data);
        } catch (error) {
          console.error('Error fetching orders:', error);
        } finally {
          setLoading(false);
        }
      };
      fetchDeliveredData();
      fetchMaterialsRecipesData();
    } else if (currentView === 'orders') {
      const fetchOrdersData = async () => {
        try {
          setLoading(true);
          const data = await fetchOrders(integration.id, orderType);
          if (integration.is_supplier) {
            const material_orders = await fetchOrders(integration.id, 'material');
            setRows(data.concat(material_orders));
          } else {
            setRows(data);
          }
        } catch (error) {
          console.error('Error fetching orders:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchOrdersData();
    }
    else if (currentView === 'incoming') {
      setTableView("table")
      const fetchOrdersData = async () => {
        try {
          setLoading(true);
          const data = await fetchShipments(null, integration.id);
          setOrdersToRows(data);
        } catch (error) {
          console.error('Error fetching orders:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchOrdersData();
    } else if (currentView === 'inventory') {
      setTableView("table")
      const fetchInventoryData = async () => {
        try {
          setLoading(true);
          const data = await fetchInventory(integration.id);
          setInventoryRows(data);
        } catch (error) {
          console.error('Error fetching orders:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchInventoryData();
    } else if (currentView === 'materials_inventory') {
      setTableView("table")
      const fetchMaterialsInventoryData = async () => {
        try {
          setLoading(true);
          const data = await fetchMaterialsInventory(integration.id);
          setMaterialInventoryRows(data);
        } catch (error) {
          console.error('Error fetching orders:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchMaterialsInventoryData();
    } else if (currentView === 'documents') {
      setTableView("table")
      const fetchDocumentsData = async () => {
        try {
          setLoading(true);
          const data = await fetchDocuments(integration.id);
          setDocumentRows(data);
        } catch (error) {
          console.error('Error fetching orders:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchDocumentsData();
    } else if (currentView === 'inbox') {
      setTableView("table")
      const fetchEmailLogsData = async () => {
        try {
          setLoading(true);
          const data = await fetchEmailLogs(integration.id);
          setEmailLogRows(data);
        } catch (error) {
          console.error('Error fetching orders:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchEmailLogsData();
    } else if (currentView === 'financials') {
      setTableView("table")
      const fetchFinancialsData = async () => {
        try {
          setLoading(true);
          const data = await fetchFinancials(integration.id);
          setFinancialsRows(data);
        } catch (error) {
          console.error('Error fetching orders:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchFinancialsData();
    }

  }, [currentView, integration, hqIntegrationId, orderType, orderToType]);

  useEffect(() => {
    if (sharedWithList.length === 0 && !initializedSharedWith) {
      const fetchSharedWithData = async () => {
        try {
          const sharedWith = await fetchIntegrationShare(integration.id);
          setShareWithList(sharedWith);
          setInitializedSharedWith(true);
        } catch (error) {
          console.error('Error fetching orders:', error);
        }
      };
      fetchSharedWithData();
    }
  }, [sharedWithList, integration, initializedSharedWith]);

  const handleAddShare = async () => {
    const new_share = await createIntegrationShare(shareWith, integration.id);
    setShareWithList([...sharedWithList, new_share]);
    setShareWith('');
    setAddingShare(false);
  };

  const handleDeleteShare = async (id) => {
    await deleteIntegrationShare(id);
    setShareWithList(sharedWithList.filter((item) => item.id !== id));
  };


  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="xl"
      >
        <Box sx={{ px: '1rem', pt: '1rem', display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            {!isEditing ? (
              <>
                <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', ml: '0.5rem', mb: 0 }}>
                  <strong>{integration.name} - {integration.integration_type.type} {integration.is_3pl ? ' and 3PL' : ''} {integration.is_supplier ? ' and Supplier' : ''}</strong>
                  <IconButton onClick={handleStartEditing} sx={{ ml: 1, verticalAlign: 'middle' }}>
                    <EditIcon sx={{ color: 'black' }} />
                  </IconButton>
                </Typography>
                <Typography variant="body2" gutterBottom style={{ display: 'flex', alignItems: 'center' }}>
                  <PlaceIcon style={{ marginRight: '0.25em' }} />
                  {integration.street_address}
                </Typography>
                <Typography variant="body2" gutterBottom style={{ display: 'flex', alignItems: 'center' }}>
                  <ReceiptIcon style={{ marginRight: '0.25em' }} onClick={() => {
                    testQuickbooksEndpoint();
                  }} />
                  {integration.billing_address}
                </Typography>
                <Typography variant="body2" gutterBottom style={{ display: 'flex', alignItems: 'center' }}>
                  <EmailIcon style={{ marginRight: '0.25em' }} onClick={() => setCurrentView('inbox')} />
                  {integration.contact_name}
                  {integration.contact_title ? ` (${integration.contact_title})` : ''}: {integration.contact_email}
                  {integration.billing_email ? ` (Billing Email: ${integration.billing_email})` : ''}
                </Typography>
                {!['HQ', '3PL'].includes(integration.integration_type.category) && (
                  <Typography variant="body2" gutterBottom style={{ display: 'flex', alignItems: 'center' }}>
                    <InfoOutlinedIcon style={{ marginRight: '0.25em' }} />
                    {integration.minimum_order_qty ? `Minimum Order Size: ${integration.minimum_order_qty} ${integration.minimum_order_units}` : ''}
                    {integration.minimum_order_qty && integration.default_lead_time ? ' | ' : ''}
                    {integration.default_lead_time ? `Default Lead Time: ${integration.default_lead_time} days` : ''}
                  </Typography>
                )}
              </>
            ) : (
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', m: 0 }}>
                  <TextField
                    label="Name"
                    value={integrationEditor.name}
                    onChange={(e) => setIntegrationEditor({ ...integrationEditor, name: e.target.value })}
                    size="small"
                    sx={{ mr: '5px' }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<SaveIcon />}
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    sx={{ ml: '5px' }}
                    onClick={() => setIsEditing(false)}
                  >
                    Cancel
                  </Button>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: '0.75rem' }}>
                  <TextField
                    label="Search Location"
                    size="small"
                    sx={{ mr: '5px', minWidth: '250px' }}
                    onChange={(e) => setLocationSearch(e.target.value)}
                    onKeyPress={handleKeyPress}
                  />
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<SearchIcon />}
                    sx={{ mr: '5px' }}
                    onClick={async () => await handleLocationLookup()}
                  >
                    Search
                  </Button>
                  <TextField
                    label="Street Address"
                    disabled
                    value={integrationEditor.street_address}
                    size="small"
                    sx={{ mr: '5px', minWidth: '400px' }}
                  />
                </Box>
                {/* <Box sx={{ display: 'flex', alignItems: 'center', mt: '0.75rem' }}>
                  <TextField
                    label="Billing Address"
                    value={integrationEditor.billing_address}
                    onChange={(e) => setIntegrationEditor({ ...integrationEditor, billing_address: e.target.value })}
                    size="small"
                    sx={{ mr: '5px', minWidth: '400px' }}
                  />
                </Box> */}
                <Box sx={{ display: 'flex', alignItems: 'center', mt: '0.75rem' }}>
                  <TextField
                    label="Contact Name"
                    value={integrationEditor.contact_name}
                    sx={{ mr: '5px' }}
                    onChange={(e) => setIntegrationEditor({ ...integrationEditor, contact_name: e.target.value })}
                    size="small"
                  />
                  <TextField
                    label="Contact Title"
                    value={integrationEditor.contact_title}
                    sx={{ mr: '5px' }}
                    onChange={(e) => setIntegrationEditor({ ...integrationEditor, contact_title: e.target.value })}
                    size="small"
                  />
                  <TextField
                    label="Contact Email"
                    value={integrationEditor.contact_email}
                    sx={{ mr: '5px' }}
                    onChange={(e) => setIntegrationEditor({ ...integrationEditor, contact_email: e.target.value })}
                    size="small"
                  />
                  <TextField
                    label="Billing Email"
                    value={integrationEditor.billing_email}
                    onChange={(e) => setIntegrationEditor({ ...integrationEditor, billing_email: e.target.value })}
                    size="small"
                  />
                </Box>
                {!['HQ', '3PL'].includes(integration.integration_type.category) && (
                  <Box sx={{ display: 'flex', alignItems: 'center', mt: '0.75rem' }}>
                    <TextField
                      label="Minimum Order Qty"
                      value={integrationEditor.minimum_order_qty}
                      type='number'
                      sx={{ mr: '5px' }}
                      onChange={(e) => setIntegrationEditor({ ...integrationEditor, minimum_order_qty: e.target.value })}
                      size="small"
                    />
                    <TextField
                      label="Unit of Measure"
                      value={integrationEditor.minimum_order_units}
                      sx={{ mr: '5px' }}
                      onChange={(e) => setIntegrationEditor({ ...integrationEditor, minimum_order_units: e.target.value })}
                      size="small"
                    />
                    <TextField
                      label="Default Lead Time"
                      value={integrationEditor.default_lead_time}
                      onChange={(e) => setIntegrationEditor({ ...integrationEditor, default_lead_time: e.target.value })}
                      size="small"
                    />
                  </Box>
                )}
              </Box>
            )}
            <Box sx={{ mt: 'auto', my: '0.5rem' }}>
              <ToggleDetailsView
                integrationType={integration.integration_type.type}
                isBrandOperated={integration.is_brand_operated}
                currentView={currentView}
                setCurrentView={setCurrentView}
              />
            </Box>
          </Box>
          <Box
            sx={{
              marginLeft: 'auto',
              py: '1rem',
              px: '0.5rem',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                alignItems: 'right',
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                {dataSources.find(ds => ds.id === integration.source_id)?.type === 'Shopify' ? (
                  <img src={shopify_logo} alt="Shopify Logo" style={{ width: '28px', height: '28px', mr: '0.5rem' }} />
                ) : dataSources.find(ds => ds.id === integration.source_id)?.type === 'Airtable' ? (
                  <img src={airtable_logo} onClick={() => testAirtableEndpoint()} alt="Airtable Logo" style={{ width: '28px', height: '28px', mr: '0.5rem' }} />
                ) : dataSources.find(ds => ds.id === integration.source_id)?.type === 'TikTok' ? (
                  <img src={tiktok_logo} onClick={() => testTikTokEndpoint()} alt="TikTok Logo" style={{ width: '28px', height: '28px', mr: '0.5rem' }} />
                ) : dataSources.find(ds => ds.id === integration.source_id)?.type === 'WooCommerce' ? (
                  <img src={woocommerce_logo} onClick={() => testWooCommerceEndpoint()} alt="WooCommerce Logo" style={{ width: '28px', height: '28px', mr: '0.5rem' }} />
                ) : dataSources.find(ds => ds.id === integration.source_id)?.type === 'Google Sheets' ? (
                  <img src={sheets_logo} onClick={() => testsSheetsEndpoint()} alt="Sheets Logo" style={{ width: '28px', height: '28px', mr: '0.5rem' }} />
                ) : (currentView === 'financials' && integration.native_finance_id) ? (
                  <img src={quickbooks_logo} alt="Quickbooks Logo" style={{ width: '28px', height: '28px', mr: '0.5rem' }} />
                ) : (
                  <img src={trinity_logo} alt="Trinity Logo" style={{ width: '28px', height: '28px', mr: '0.5rem' }} />
                )}
                <Typography
                  variant="body1"
                  color="black"
                  display="block"
                  sx={{
                    ml: '0.25rem',
                    fontFamily: (dataSources.find(ds => ds.id === integration.source_id)?.type || (currentView === 'financials' && integration.native_finance_id)) ? 'inherit' : 'MontHeavy',
                    fontSize: (dataSources.find(ds => ds.id === integration.source_id)?.type || (currentView === 'financials' && integration.native_finance_id)) ? 'inherit' : '20px'
                  }}
                >
                  <b>{(dataSources.find(ds => ds.id === integration.source_id)?.type) || (currentView === 'financials' && integration.native_finance_id ? 'Quickbooks' : 'trinity')}</b>
                </Typography>
              </Box>
              {dataSources.find(ds => ds.id === integration.source_id) ? (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="body1" color="black">
                    {`Last Synced: ${format(
                      fromZonedTime(
                        new Date(dataSources.find(ds => ds.id === integration.source_id)?.last_updated),
                        'UTC'
                      ),
                      'MM/dd/yy HH:mm'
                    )}`}
                  </Typography>
                  <CheckCircleOutlinedIcon sx={{ color: 'green', ml: '0.5rem' }} />
                </Box>
              ) : currentView === 'financials' && integration.native_finance_id ? (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="body1" color="black">
                    {`Last Synced: ${format(
                      fromZonedTime(
                        new Date(dataSources.find(ds => ds.type === 'Quickbooks')?.last_updated),
                        'UTC'
                      ),
                      'MM/dd/yy HH:mm'
                    )}`}
                  </Typography>
                  <CheckCircleOutlinedIcon sx={{ color: 'green', ml: '0.5rem' }} />
                </Box>
              ) : null
              }
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', p: '0.25rem' }}>
              {integration.integration_type.type === 'Manufacturer' && (
                <>
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Tooltip title="Share products and materials tables with partner(s) to allow them to update inventory levels directly.">
                      <IconButton
                        color="info"
                        onClick={() => setAddingShare(!addingShare)}
                      >
                        <PeopleAltIcon />
                      </IconButton>
                    </Tooltip>
                    <Typography variant="body2" color="black" sx={{ ml: 0.5 }} display={'flex'} alignItems={'center'}>
                      Partner Sharing
                    </Typography>
                    <Tooltip title="After a partner is added, simply ask the partner to create an account and login to Trinity (app.trinity3.co). They will be able to access and update values from their linked account. They will only be able to view information relevant to them.">
                      <HelpIcon fontSize="small" sx={{ ml: 1 }} />
                    </Tooltip>
                  </Box>

                  <Box display="flex" flexDirection="row" alignItems="center" justifyContent="right">
                    {!addingShare ? (
                      sharedWithList.map((item, index) => (
                        <Typography
                          key={index}
                          variant="body2"
                          color="black"
                          sx={{ ml: 0.5 }}
                          display="flex"
                          alignItems="center"
                        >
                          <IconButton
                            color="inherit"
                            onClick={() => handleDeleteShare(item.id)}
                          >
                            <CloseIcon color="error" fontSize="small" />
                          </IconButton>
                          {item.share_to_email}
                        </Typography>
                      ))
                    ) : (
                      <>
                        <Button
                          variant="outlined"
                          size="small"
                          sx={{ mr: 1 }}
                          onClick={() => setAddingShare(!addingShare)}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          size="small"
                          sx={{ mr: 1 }}
                          onClick={handleAddShare}
                        >
                          Share
                        </Button>
                        <TextField
                          label="Share with (email address)"
                          variant="outlined"
                          size="small"
                          autoComplete="off"
                          sx={{ minWidth: '250px' }}
                          value={shareWith}
                          onChange={(e) => setShareWith(e.target.value)}
                        />
                      </>
                    )}
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', px: '1rem', pb: '1.5rem' }}>
          {currentView === 'orders' ? (
            <OrdersDialogDatagrid
              logoBlob={logoBlob}
              orderType={orderType}
              rows={rows}
              setRows={setRows}
              materialsRows={materialsRows}
              productsRows={productsRows}
              integration={integration}
              integrations={integrations}
              tableView={tableView}
              setTableView={setTableView}
              setSnackbarOpen={setSnackbarOpen}
              setSnackbarMessage={setSnackbarMessage}
              setSnackbarAlert={setSnackbarAlert}
              loading={loading}
            />
          ) : currentView === 'materials_inventory' ? (
            <MaterialsInventoryDialogDatagrid
              integrationId={integration.id}
              integration={integration}
              integrationType={integration.integration_type.type}
              materialsRows={materialsRows}
              materialInventoryRows={materialInventoryRows}
              setMaterialInventoryRows={setMaterialInventoryRows}
              setSnackbarOpen={setSnackbarOpen}
              setSnackbarMessage={setSnackbarMessage}
              setSnackbarAlert={setSnackbarAlert}
              loading={loading}
            />
          ) : currentView === 'inventory' ? (
            <InventoryDialogDatagrid
              integrationId={integration.id}
              integration={integration}
              integrationType={integration.integration_type.type}
              is3PL={integration.is_3pl}
              productsRows={productsRows}
              materialsRows={materialsRows}
              inventoryRows={inventoryRows}
              setInventoryRows={setInventoryRows}
              setSnackbarOpen={setSnackbarOpen}
              setSnackbarMessage={setSnackbarMessage}
              setSnackbarAlert={setSnackbarAlert}
              loading={loading}
            />
          ) : currentView === 'incoming' ? (
            <Box sx={{ display: 'flex', width: '100%', height: '65vh', maxHeight: '65vh' }}>
              <ShipmentsDatagrid
                rows={ordersToRows}
                setRows={setOrdersToRows}
                setSnackbarOpen={setSnackbarOpen}
                setSnackbarMessage={setSnackbarMessage}
                setSnackbarAlert={setSnackbarAlert}
                loading={loading}
                hideCols={{ carrier: false, bol_string: false, tracking_number: false }}
              />
            </Box>
          ) : currentView === 'products' ? (
            <ProductsDialogDatagrid
              integrationId={integration.id}
              productsRows={productsRows}
              deliveredIds={deliveredIds}
              setProductsRows={setProductsRows}
              materialsRows={materialsRows}
              setSnackbarOpen={setSnackbarOpen}
              setSnackbarMessage={setSnackbarMessage}
              setSnackbarAlert={setSnackbarAlert}
              loading={loading}
            />
          ) : currentView === 'materials' ? (
            <MaterialsDialogDatagrid
              integrationId={integration.id}
              productsRows={productsRows}
              deliveredIds={deliveredIds}
              materialsRecipesIds={materialsRecipesIds}
              materialsRows={materialsRows}
              setMaterialsRows={setMaterialsRows}
              setSnackbarOpen={setSnackbarOpen}
              setSnackbarMessage={setSnackbarMessage}
              setSnackbarAlert={setSnackbarAlert}
              loading={loading}
            />
          ) : currentView === 'documents' ? (
            <Box sx={{ width: '100%', height: '65vh', maxHeight: '65vh' }}>
              <DocumentDatagrid
                rows={documentRows}
                setRows={setDocumentRows}
                materialsRows={materialsRows}
                productsRows={productsRows}
                setProductsRows={setProductsRows}
                setMaterialsRows={setMaterialsRows}
                integration={integration}
                integrations={integrations}
                setSnackbarOpen={setSnackbarOpen}
                setSnackbarMessage={setSnackbarMessage}
                setSnackbarAlert={setSnackbarAlert}
                height='65vh'
                loading={loading}
              />
            </Box>
          ) : currentView === 'inbox' ? (
            <EmailLogsDatagrid
              rows={emailLogRows}
              loading={loading}
            />
          ) : currentView === 'financials' ? (
            <FinancialsDatagrid
              rows={financialsRows}
              loading={loading}
            />
          ) : null
          }
        </Box>
      </Dialog >
    </>
  );
};


export default IntegrationDialogView;
