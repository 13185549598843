import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
  Box,
  CardActionArea,
  Card,
  CardContent,
  TextField
} from '@mui/material';
import shopify_logo from '../assets/icons8-shopify-48.png';

const ShopifyLink = () => {
  const API_KEY = process.env.REACT_APP_SHOPIFY_CLIENT;
  const REDIRECT_URI = 'https://www.' + process.env.REACT_APP_REDIRECT_URI;
  const SCOPES = 'read_products,read_inventory,read_locations,read_shopify_payments_payouts';
  const [shopName, setShopName] = useState('');
  const [shopNameDialogOpen, setShopNameDialogOpen] = useState(false);

  const handleAuthorizeShopify = () => {
    const authUrl = `https://${shopName}/admin/oauth/authorize?client_id=${API_KEY}&scope=${SCOPES}&redirect_uri=${encodeURIComponent(REDIRECT_URI)}`;
    window.location.href = authUrl;
  };

  return (
    <>
      <Card sx={{ width: '250px', height: '95%', borderRadius: '15px' }}>
        <CardActionArea sx={{ height: '100%' }}>
          <CardContent>
            <Box display="flex" alignItems="center">
              <img src={shopify_logo} alt="Shopify Logo" style={{ width: '25px', height: '25px' }} />
            </Box>
            <Typography variant="body1" color="black">
              Shopify
            </Typography>
            <Typography variant="subtitle2" sx={{ color: 'text.secondary', textAlign: 'left' }}>
              Link inventory from Shopify.
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      <Dialog
        open={shopNameDialogOpen}
        onClose={() => setShopNameDialogOpen(false)}
        fullWidth
        maxWidth={'md'}
      >
        <DialogTitle>Specify shop name</DialogTitle>
        <DialogContent>
          <Typography sx={{ color: 'text.secondary', mb: '1rem' }}>Enter shop url, for example: <b>shop.myshopify.com</b> (do NOT include <b>https://www.</b>)</Typography>
          <TextField
            size="small"
            fullWidth
            value={shopName}
            onChange={(e) => setShopName(e.target.value)}
            placeholder="Shop Name"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShopNameDialogOpen(false)} variant="text">Close</Button>
          <Button onClick={handleAuthorizeShopify} variant="text">Authorize</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ShopifyLink;
