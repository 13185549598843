import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import Typography from '@mui/material/Typography';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';

export default function ToggleDetailsViewV2({ currentSubView, setCurrentSubView }) {
  const buttonStyles = {
    color: '#2b2b2b',
    backgroundColor: 'transparent',
    border: '1px solid black',
    borderRadius: '10px',
    '&.Mui-selected': {
      backgroundColor: '#2b2b2b',
      color: 'white',
      '&:hover': {
        backgroundColor: '#2b2b2b',
      },
    },
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
  };

  const handleViewChange = (event, newValue) => {
    if (newValue !== null) {
      setCurrentSubView(newValue);
    }
  };

  return (
    <>
      <ToggleButtonGroup
        value={currentSubView}
        exclusive
        onChange={handleViewChange}
        aria-label="entity-view"
        size='small'
      >
        <ToggleButton value="Header" aria-label="documents" sx={buttonStyles}>
          <SummarizeIcon />
          <Typography variant="body2" sx={{ mx: 1, textTransform: 'none' }}>Header</Typography>
        </ToggleButton>
        <ToggleButton value="Itemized" aria-label="documents" sx={buttonStyles}>
          <ViewHeadlineIcon />
          <Typography variant="body2" sx={{ mx: 1, textTransform: 'none' }}>Itemized</Typography>
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  );
}
