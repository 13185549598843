import React, { useEffect } from 'react';
import { Box, Button, IconButton, Checkbox   } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddIcon from '@mui/icons-material/Add';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { createInventoryV2, updateInventoryV2, deleteInventoryV2 } from '../../api/inventoryV2';
import InventorySearchAutocomplete from '../InventorySearchAutocomplete';
import { format } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';
import { fetchOrderDetailsV2, updateOrderDetailV2 } from '../../api/ordersV2';

export default function InventoryV2({
  rows,
  setRows,
  integration,
  currentView,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarAlert,
  loading = null,
  height = '83vh'
}) {
  const [detailRows, setDetailRows] = React.useState([]);

  useEffect(() => {
    setDetailRows([]);
  }, [currentView]);


  const handleDetailRowUpdate = async (newRow, oldRow) => {
    await updateOrderDetailV2(newRow.id, newRow);
    return newRow;
  };

  const handleFetchInventory = async (row) => {
    let to_flag = false;
    if (integration.integration_type.category === 'Manufacturer' && row.item_type === 'material') {
      to_flag = true
    } else if (integration.integration_type.category === '3PL' && row.item_type === 'product') {
      to_flag = true
    }
    let data;
    if (to_flag) {
      data = await fetchOrderDetailsV2(null, null, row.integration_id, row.item);
    } else {
      data = await fetchOrderDetailsV2(null, row.integration_id, null, row.item);
    }
    setDetailRows(data);
  }

  const columns = [
    { field: 'item', headerName: 'Item', flex: 1.5, editable: true },
    {
      field: 'is_active', headerName: 'Active', flex: 0.5, renderCell: (params) => (
        <Checkbox
          checked={!!params.value}
          onChange={(event) => {
            const newValue = event.target.checked;
            const updatedRow = { ...params.row, is_active: newValue };
            handleProcessRowUpdate(updatedRow);
          }}
          sx={{
            color: 'black',
            '&.Mui-checked': {
              color: 'black',
            },
          }}
        />
      )
    },
    {
      field: 'avg_order_price',
      headerName: 'Avg. Cost ($)',
      align: 'left',
      headerAlign: 'left',
      flex: 1,
      type: 'number',
      renderCell: (params) => params.value === 0 ? '$0.00' : params.value ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(params.value) : '',
    },
    {
      field: 'total_order_qty',
      headerName: 'Total Order Qty',
      align: 'left',
      headerAlign: 'left',
      flex: 1,
      type: 'number',
      renderCell: (params) => params.value === 0 ? '0' : params.value ? new Intl.NumberFormat('en-US').format(params.value) : '',
    },
    {
      field: 'qty',
      headerName: 'Qty',
      align: 'left',
      headerAlign: 'left',
      flex: 1,
      type: 'number',
      editable: true,
      renderCell: (params) => params.value === 0 ? '0' : params.value ? new Intl.NumberFormat('en-US').format(params.value) : '',
    },
    { field: 'unit_of_measure', headerName: 'Unit of Measure', flex: 1, editable: true },
    { field: 'edited_by', headerName: 'Edited By', flex: 1 },
    {
      field: 'last_updated', headerName: 'Last Updated', width: 140,
      renderCell: (params) => {
        const value = params.value || params.row.create_time;
        if (!value) {
          return '';
        }
        const date = new Date(value);
        const zonedDate = fromZonedTime(date, 'UTC');
        return format(zonedDate, 'MM/dd/yy HH:mm');
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <>
          <IconButton onClick={() => handleFetchInventory(params.row)}>
            <ViewHeadlineIcon color='info' />
          </IconButton>
          <IconButton onClick={() => {
            deleteInventoryV2(params.row.id)
            setRows(rows.filter((row) => row.id !== params.row.id));
          }}
          >
            <DeleteOutlineOutlinedIcon color='error' />
          </IconButton>
        </>
      ),
    },
  ];

  const detailColumns = [
    { field: 'partner_name', headerName: 'From', flex: 1.25, headerClassName: 'super-app-theme--header-new' },
    { field: 'order_name', headerName: 'Order', flex: 1.5, headerClassName: 'super-app-theme--header-new' },
    { field: 'status', headerName: 'Status', flex: 1, headerClassName: 'super-app-theme--header-new' },
    {
      field: 'item_name',
      headerName: 'Item',
      flex: 2.5,
      headerClassName: 'super-app-theme--header-new',
    },
    {
      field: 'invoice_qty',
      headerName: 'Qty',
      flex: 0.75,
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      editable: true,
      headerClassName: 'super-app-theme--header-new',
      renderCell: (params) => params.value ? new Intl.NumberFormat('en-US').format(params.value) : new Intl.NumberFormat('en-US').format(params.row.order_qty)
    },
    {
      field: 'item_unit_of_measure',
      headerName: 'UoM',
      flex: 1,
      headerClassName: 'super-app-theme--header-new',
    },
    {
      field: 'item_price',
      headerName: 'Unit Price',
      flex: 1,
      headerClassName: 'super-app-theme--header-new',
      editable: true,
      renderCell: (params) => params.value ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(params.value) : ''
    },
    {
      field: 'lot_number',
      headerName: 'Lot',
      flex: 1.5,
      headerClassName: 'super-app-theme--header-new',
      editable: true
    },
    {
      field: 'expiration_date_raw',
      headerName: 'Exp. Date',
      flex: 1,
      headerClassName: 'super-app-theme--header-new',
      editable: true
    },
    {
      field: 'order_date', headerName: 'Order Date', flex: 1, headerClassName: 'super-app-theme--header-new',
      renderCell: (params) => {
        if (!params.value) {
          return '';
        }
        const date = new Date(params.value);
        return isNaN(date.getTime()) ? '' : date.toISOString().slice(0, 10);
      }
    },
  ];

  const handleSelect = async (item) => {
    try {
      if (item) {
        const data = await createInventoryV2({
          integration_id: integration.id,
          item: item,
          item_type: currentView,
        });
        setRows([data, ...rows]);
      }
    } catch (error) {
      console.error('Error:', error);
      setSnackbarOpen(true);
      setSnackbarMessage(error.response.data.detail);
      setSnackbarAlert("error");
    }
  };

  const handleCreateItem = async () => {
    try {
      const data = await createInventoryV2({
        integration_id: integration.id,
        item: 'New Item',
        item_type: currentView,
      });
      setRows([data, ...rows]);
    } catch (error) {
      console.error('Error:', error);
      setSnackbarOpen(true);
      setSnackbarMessage(error.response.data.detail);
      setSnackbarAlert("error");
    }
  };


  const handleProcessRowUpdate = async (updatedRow, oldRow) => {
    await updateInventoryV2(updatedRow);
    const updatedRows = rows.map((row) => (row.id === updatedRow.id ? updatedRow : row));
    setRows(updatedRows);
    return updatedRow;
  };

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <Button
        color="inherit"
        variant='outlined'
        startIcon={<AddIcon />}
        onClick={handleCreateItem}
        sx={{ borderRadius: '10px' }}
      >
        Create New {currentView}
      </Button>
      <InventorySearchAutocomplete onSelect={handleSelect} />
    </GridToolbarContainer>
  );

  const DetailToolbar = () => (
    <GridToolbarContainer>
      <Button
        color="inherit"
        variant='outlined'
        startIcon={<ArrowBackIcon />}
        onClick={() => setDetailRows([])}
        sx={{ borderRadius: '10px' }}
      >
        Back
      </Button>
    </GridToolbarContainer>
  );

  return (
    <Box
      sx={{
        width: '100%',
        height: height,
        maxHeight: height,
      }}
    >
      {detailRows.length === 0 ? (
        <DataGrid
          rows={rows}
          loading={loading}
          columns={columns}
          density="compact"
          hideFooter
          processRowUpdate={handleProcessRowUpdate}
          slots={{
            toolbar: CustomToolbar,
          }}
        />
      ) : (
        <DataGrid
          rows={detailRows}
          loading={loading}
          columns={detailColumns}
          density="compact"
          hideFooter
          processRowUpdate={handleDetailRowUpdate}
          slots={{
            toolbar: DetailToolbar,
          }}
        />
      )}
    </Box>
  );
}
