import { createTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    primary: {
      main: "#2b2b2b",
      dark: grey[700]
    },
    secondary: {
      main: grey[600],
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
          border: '0.5px solid grey',
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
          borderRadius: '10px',
          border: '0.5px solid grey',
        },
      },
    },
  },
});

export default theme;
