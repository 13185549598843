import { axiosInstance, checkToken } from './utils.js'



const fetchOrdersV2 = async (searchTerm = null, integration_id = null, integration_id_to = null) => {
  const params = new URLSearchParams();
  if (searchTerm) params.append('search_term', searchTerm);
  if (integration_id) params.append('integration_id', integration_id);
  if (integration_id_to) params.append('integration_id_to', integration_id_to);
  const queryString = params.toString();
  const url = `/v2/orders/` + (queryString ? `?${queryString}` : '');
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get(url);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};


const fetchOrderDetailsV2 = async (searchTerm = null, integration_id = null, integration_id_to = null, item_name = null) => {
  const params = new URLSearchParams();
  if (searchTerm) params.append('search_term', searchTerm);
  if (integration_id) params.append('integration_id', integration_id);
  if (integration_id) params.append('integration_id', integration_id);
  if (integration_id_to) params.append('integration_id_to', integration_id_to);
  if (item_name) params.append('item_name', item_name);
  const queryString = params.toString();
  const url = `/v2/orders_details/` + (queryString ? `?${queryString}` : '');
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get(url);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

const fetchDocumentsV2 = async (
  searchTerm = null,
  is_unlinked = null,
  is_pinned,
  integration_id = null,
  order_header_id = null,
  order_detail_id = null
) => {
  const params = new URLSearchParams();
  if (searchTerm) params.append('search_term', searchTerm);
  if (is_unlinked) params.append('is_unlinked', is_unlinked);
  if (is_pinned) params.append('is_pinned', is_pinned);
  if (integration_id) params.append('integration_id', integration_id);
  if (order_header_id) params.append('order_header_id', order_header_id);
  if (order_detail_id) params.append('order_detail_id', order_detail_id);
  const queryString = params.toString();
  const url = `/v2/documents/` + (queryString ? `?${queryString}` : '');
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get(url);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

const unlinkDocumentV2 = async (document_id) => {
  const url = `/v2/unlink/${document_id}`;
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).patch(url);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

const createOrderV2 = async (order) => {
  const url = '/v2/orders/';
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).post(url, order);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};


const updateOrderV2 = async (order_id, order) => {
  const url = `/v2/orders/${order_id}`;
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).patch(url, order);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

const updateOrderDetailV2 = async (order_detail_id, order_detail) => {
  const url = `/v2/orders_details/${order_detail_id}`;
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).patch(url, order_detail);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};


const updateOrderDocumentsV2 = async (order_id, document_id) => {
  const url = `/v2/order_documents/${order_id}/${document_id}`;
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).patch(url);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};


const deleteOrderV2 = async (id) => {
  const url = '/v2/orders/';
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).delete(`${url}${id}`);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};


const togglePinDocumentV2 = async (id) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).patch(`/v2/toggle_pin_document/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

const sendDocs = async (documents, subject, toEmail, ccList, emailBody) => {
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).post('/send_docs/', {
      subject: subject,
      to_email: toEmail,
      cc_list: ccList,
      email_body: emailBody,
      documents: documents
    });
    return response.data;
  } catch (error) {
    console.error('Error creating product:', error);
    throw error;
  }
};


export {
  togglePinDocumentV2,
  fetchOrdersV2,
  fetchOrderDetailsV2,
  fetchDocumentsV2,
  createOrderV2,
  sendDocs,
  unlinkDocumentV2,
  updateOrderV2,
  updateOrderDetailV2,
  updateOrderDocumentsV2,
  deleteOrderV2
};
