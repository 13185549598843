import React from 'react';
import {
  Box,
  Button,
  Divider,
  Typography,
  Tooltip,
  TextField,
  InputAdornment,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  FormControlLabel,
  Switch,
} from '@mui/material';
import trinity_logo from '../assets/logo.png';
import LogoutIcon from '@mui/icons-material/Logout';
// import SettingsIcon from '@mui/icons-material/Settings';
import StyledIconButton from '../components/StyledIconButton';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CreatePartnerDialog from '../components/CreatePartnerDialog';
import ViewListIcon from '@mui/icons-material/ViewList';
import PublicIcon from '@mui/icons-material/Public';
import SyncIcon from '@mui/icons-material/Sync';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import InfoIcon from '@mui/icons-material/Info'
import PushPinIcon from '@mui/icons-material/PushPin';
import SearchIcon from '@mui/icons-material/Search';
import gmail_logo from '../assets/icons8-gmail-48.png';
import { useGoogleAuth } from '../components/GoogleAuthContext';
import { fetchIntegrationTypes, fetchIntegrations, deleteIntegration } from '../api/integrations';
import { refreshDocuments } from '../api/documents';
import { fetchOrdersV2, fetchDocumentsV2, fetchOrderDetailsV2 } from '../api/ordersV2';
import { fetchSources, deleteSource } from '../api/notifications';
import DocumentsV2 from '../components/datagrid/DocumentsV2';
import OrdersV2 from '../components/datagrid/OrdersV2';
import OrderDetailsV2 from '../components/datagrid/OrderDetailsV2';
import SnackbarAlert from '../components/SnackbarAlert';
import DocumentsPopover from '../components/DocumentsPopover';
import SettingsDialog from '../components/SettingsDialog';
import { format } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';
import MapComponentV2 from '../components/MapComponentV2';
import IntegrationDialogViewV2 from '../components/IntegrationDialogViewV2';


const EmailClient = ({
  user,
  setEmailClient,
  signOut
}) => {
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarAlert, setSnackbarAlert] = React.useState("");
  const [selectedTable, setSelectedTable] = React.useState("Map");
  const [selectedSubView, setSelectedSubView] = React.useState("Header");
  const [integrations, setIntegrations] = React.useState([]);
  const [dataSources, setDataSources] = React.useState([]);
  const [orderRows, setOrderRows] = React.useState([]);
  const [documentRows, setDocumentRows] = React.useState([]);
  const [inventoryRows, setInventoryRows] = React.useState([]);
  const [filteredIntegrationRows, setFilteredIntegrationRows] = React.useState([]);
  const [integrationTypes, setIntegrationTypes] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [partnerOpen, setPartnerOpen] = React.useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [deletingId, setDeletingId] = React.useState("");
  const [showUnlinkedOnly, setShowUnlinkedOnly] = React.useState(true);
  const [settingsDialogOpen, setSettingsDialogOpen] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [docsAnchorEl, setDocsAnchorEl] = React.useState(null);
  const [pinnedDocuments, setPinnedDocuments] = React.useState([]);
  const [selectedIntegration, setSelectedIntegration] = React.useState("");
  const { gmailLogin } = useGoogleAuth();

  React.useEffect(() => {
    const fetchIntegrationsData = async () => {
      try {
        const integrations_data = await fetchIntegrations();
        const integration_types_data = await fetchIntegrationTypes();
        setIntegrations(integrations_data);
        setIntegrationTypes(integration_types_data);
      } catch (error) {
        console.error('Error fetching integrations:', error);
      }
    };

    const fetchDataSourcesData = async () => {
      try {
        const sources_data = await fetchSources();
        setDataSources(sources_data);
      } catch (error) {
        console.error('Error fetching integrations:', error);
      }
    };

    fetchIntegrationsData();
    fetchDataSourcesData();
  }, []);

  React.useEffect(() => {
    setSearchTerm("");
    setFilteredIntegrationRows([]);
    if (selectedTable === "Orders") {
      setLoading(true);
      const fetchOrdersData = async () => {
        try {
          const orders_data = await fetchOrdersV2();
          setOrderRows(orders_data);
        } catch (error) {
          console.error('Error fetching orders:', error);
        } finally {
          setLoading(false);
        }
      };

      const fetchInventoryData = async () => {
        try {
          const details_data = await fetchOrderDetailsV2();
          setInventoryRows(details_data);
        } catch (error) {
          console.error('Error fetching inventory:', error);
        } finally {
          setLoading(false);
        }
      };

      if (selectedSubView === "Header") {
        fetchOrdersData();
      } else if (selectedSubView === "Itemized") {
        fetchInventoryData();
      }
    } else if (selectedTable === "Documents") {
      setLoading(true);
      const fetchDocumentData = async () => {
        try {
          const documents_data = await fetchDocumentsV2(null, true);
          setDocumentRows(documents_data);
        } catch (error) {
          console.error('Error fetching documents:', error);
        } finally {
          setLoading(false);
        }
      };
      fetchDocumentData();
    }
  }, [selectedTable, selectedSubView]);


  const handleLogout = async () => {
    localStorage.removeItem('apiToken');
    await signOut();
  };

  const handleClose = () => {
    setDialogOpen(false);
  };


  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSearchData = async (event) => {
    setLoading(true);
    if (event.key === 'Enter') {
      setSearchTerm(event.target.value);
      if (selectedTable === "Orders") {
        if (selectedSubView === "Header") {
          const filteredOrders = await fetchOrdersV2(event.target.value);
          setOrderRows(filteredOrders);
          setLoading(false);
        } else if (selectedSubView === "Itemized") {
          const filteredInventory = await fetchOrderDetailsV2(event.target.value);
          setInventoryRows(filteredInventory);
          setLoading(false);
        }
      } else if (selectedTable === "Documents") {
        const filteredOrders = await fetchDocumentsV2(event.target.value, showUnlinkedOnly);
        setDocumentRows(filteredOrders);
        setLoading(false);
      } else if (selectedTable === "Inventory") {
        const filteredInventory = await fetchOrderDetailsV2(event.target.value);
        setInventoryRows(filteredInventory);
        setLoading(false);
      } else if (selectedTable === "Map" && event.target.value) {
        const filteredData = await fetchOrderDetailsV2(event.target.value);
        const uniqueIntegrationIds = [...new Set(filteredData.map(row => row.integration_id))];
        const uniqueIntegrationIdsTo = [...new Set(filteredData.map(row => row.integration_id_to))];
        const allUniqueIntegrationIds = new Set([...uniqueIntegrationIds, ...uniqueIntegrationIdsTo]);
        setFilteredIntegrationRows([...allUniqueIntegrationIds]);
        setLoading(false);
      } else {
        setFilteredIntegrationRows([]);
        setLoading(false);
      }
    }
  };

  const handleDeletePartner = async () => {
    await deleteIntegration(deletingId);
    setIntegrations((prevIntegrations) => prevIntegrations.filter((integration) => integration.id !== deletingId));
    setDeletingId("");
    setDeleteDialogOpen(false);
  };

  const handleRefreshDocuments = async (integration_id) => {
    try {
      await refreshDocuments(integration_id);
      setSnackbarOpen(true);
      setSnackbarMessage("Refresh triggered. Check back in a minute.");
      setSnackbarAlert("success");
    } catch (error) {
      console.error('Error:', error);
      setSnackbarOpen(true);
      setSnackbarMessage(error.response.data.detail);
      setSnackbarAlert("error");
    }
  };

  const handleToggleUnlinked = async (e) => {
    setLoading(true);
    setShowUnlinkedOnly(e);
    const documents = await fetchDocumentsV2(searchTerm, e);
    setDocumentRows(documents);
    setLoading(false);
  }


  const handlePinnedDocuments = async (event, integration_id) => {
    const pinned_docs = await fetchDocumentsV2(null, null, "True", integration_id);
    setPinnedDocuments(pinned_docs);
    setDocsAnchorEl(event.target)
  };


  return (
    <Box sx={{ height: '100vh', bgcolor: '#ebebeb', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', p: '0.25rem' }}>
      <Box
        sx={{
          width: '100%',
          flex: '0 0 auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            bgcolor: '#2b2b2b',
            py: '0.3rem',
            px: '1rem',
            borderRadius: '15px',
          }}
        >
          <img src={trinity_logo} alt="Trinity Logo" onClick={() => setEmailClient(false)} style={{ width: '95px' }} />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {/* <StyledIconButton icon={<SettingsIcon />} onClick={() => setSettingsDialogOpen(true)} tooltip={'Settings'} /> */}
            <StyledIconButton icon={<LogoutIcon />} onClick={handleLogout} tooltip={'Logout'} />
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="left"
          justifyContent="center"
          sx={{
            width: '100%',
          }}
        >
          <Box
            sx={{
              width: '20%',
              height: '90vh',
              maxHeight: '90vh',
              overflowY: 'auto',
              p: '0.5rem',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'top',
              alignItems: 'center',
              '&::-webkit-scrollbar': {
                bgcolor: '#ebebeb',
                width: '3px',
              },
              '&::-webkit-scrollbar-thumb': {
                bgcolor: 'darkgrey',
                borderRadius: '15px',
              },
            }}
          >
            <Box sx={{
              display: 'flex',
              alignItems: 'left',
              flexDirection: 'column',
              width: '100%',
              border: '1px solid #ccc',
              my: '0.25rem',
              borderRadius: '10px',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              p: 1,
              bgcolor: 'white',
            }}>
              <Typography variant="body2" display={'flex'}>
                <b>Linked Emails</b>
              </Typography>
              {dataSources
                .filter(dataSource => dataSource.type === 'Gmail')
                .map(dataSource => (
                  <Typography key={dataSource.id} variant="body2" display={'flex'} alignItems={'center'}>
                    <Tooltip title={`Last updated: ${format(fromZonedTime(new Date(dataSource.last_updated), 'UTC'), 'MM/dd/yyyy hh:mm:ss a')}`} placement="right" arrow>
                      <CheckCircleOutlineIcon fontSize='small' sx={{ color: 'green', mr: '0.25rem' }} />
                    </Tooltip>
                    {dataSource.email}
                    <DeleteOutlineOutlinedIcon
                      fontSize='small'
                      color='error'
                      sx={{ ml: '0.25rem', cursor: 'pointer' }}
                      onClick={async () => {
                        await deleteSource(dataSource.id);
                        setDataSources(dataSources.filter(source => source.id !== dataSource.id));
                      }}
                    />
                  </Typography>
                ))}
              <Button
                variant="outlined"
                startIcon={<img src={gmail_logo} alt="Gmail Logo" style={{ width: '25px', height: '25px' }} />}
                onClick={gmailLogin}
                sx={{
                  width: '100%',
                  borderRadius: '10px',
                  border: '1px solid #ccc',
                  textTransform: 'none',
                  my: '0.25rem',
                }}
              >
                Link Gmail
              </Button>
            </Box>
            <Divider color="#ccc" sx={{ my: '0.5rem', width: '100%' }} />
            <Button
              variant="contained"
              onClick={() => setDialogOpen(true)}
              startIcon={<AddIcon />}
              sx={{
                width: '100%',
                height: '2.5rem',
                borderRadius: '10px',
                textTransform: 'none',
                bgcolor: "#2b2b2b",
                my: '0.25rem',
              }}
            >
              Add Partner
            </Button>
            {[...integrations.filter(integration => integration.integration_type.category === 'HQ'), ...integrations.filter(integration => integration.integration_type.category !== 'HQ')].map(integration => (
              <Box
                key={integration.id}
                sx={{
                  width: '100%',
                  p: '1rem',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  bgcolor: 'white',
                  borderRadius: '10px',
                  gap: '0.25rem',
                  border: integration.integration_type.category === 'HQ' ? '1px solid black' : '1px solid #ccc',
                  my: '0.25rem',
                  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: '1 1 auto',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setSelectedIntegration(integration)
                    setPartnerOpen(true)
                  }}
                >
                  <Typography variant="body1" display={'flex'} alignItems={'center'}>
                    <b>{integration.name}</b>
                  </Typography>
                  <Typography variant="body2">{integration.integration_type.type}</Typography>
                  <Typography variant="body2">{integration.contact_email}</Typography>
                </Box>
                <Divider orientation="vertical" flexItem />
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1px' }}>
                  <DocumentsPopover
                    anchorEl={docsAnchorEl}
                    setAnchorEl={setDocsAnchorEl}
                    selectedDocuments={pinnedDocuments}
                  />
                  <PushPinIcon
                    color='secondary'
                    fontSize='small'
                    sx={{ cursor: 'pointer' }}
                    onClick={(event) => handlePinnedDocuments(event, integration.id)}
                  />
                  {integration.integration_type.category !== 'HQ' && (
                    <>
                      <Tooltip title="Sync Now" placement="right">
                        <SyncIcon
                          color='info'
                          fontSize='small'
                          onClick={() => handleRefreshDocuments(integration.id)}
                          sx={{ cursor: 'pointer' }}
                        />
                      </Tooltip>
                      <Tooltip title="Delete" placement="right">
                        <DeleteOutlineOutlinedIcon color='error' sx={{ cursor: 'pointer' }} fontSize='small' onClick={() => {
                          setDeletingId(integration.id);
                          setDeleteDialogOpen(true)
                        }} />
                      </Tooltip>
                    </>
                  )}
                </Box>
              </Box>
            ))}
          </Box>
          <Divider orientation="vertical" flexItem sx={{ mt: '1rem' }} />
          <Box
            sx={{
              width: '80%',
              height: '90vh',
              p: '0.5rem',
            }}
          >
            <Box
              sx={{
                width: '100%',
                gap: '0.5rem',
                pt: '0.5rem',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box
                sx={{
                  gap: '1rem',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '1rem',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    variant={selectedTable === 'Map' ? 'contained' : "text"}
                    onClick={() => setSelectedTable('Map')}
                    startIcon={<PublicIcon />}
                    sx={{
                      borderRadius: '10px',
                      textTransform: 'none',
                      minWidth: '125px',
                    }}
                  >
                    Map
                  </Button>
                  <Button
                    variant={selectedTable === 'Documents' ? 'contained' : "text"}
                    onClick={() => setSelectedTable('Documents')}
                    startIcon={<DocumentScannerIcon />}
                    sx={{
                      borderRadius: '10px',
                      textTransform: 'none',
                      minWidth: '125px',
                    }}
                  >
                    Documents
                  </Button>
                  <Button
                    variant={selectedTable === 'Orders' ? 'contained' : "text"}
                    startIcon={<ViewListIcon />}
                    onClick={() => setSelectedTable('Orders')}
                    sx={{
                      borderRadius: '10px',
                      textTransform: 'none',
                      minWidth: '125px',
                    }}
                  >
                    Orders
                  </Button>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    flex: 1,
                  }}
                >
                  {['Orders', 'Documents', 'Inventory', 'Map'].includes(selectedTable) && (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        width: '100%',
                        gap: '1rem',
                      }}
                    >
                      {selectedTable === 'Documents' && (
                        <FormControlLabel
                          label={
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                              Unlinked Only
                              <Tooltip title="Documents that have not yet been linked to an order." arrow>
                                <InfoIcon fontSize="small" sx={{ ml: 0.5, color: 'grey.600' }} />
                              </Tooltip>
                            </span>
                          }
                          control={
                            <Switch
                              checked={showUnlinkedOnly}
                              onChange={(e) => handleToggleUnlinked(e.target.checked)}
                              color="primary"
                              sx={{ ml: 0.5 }}
                            />
                          }
                          sx={{
                            '& .MuiFormControlLabel-label': {
                              fontSize: '0.85rem',
                              whiteSpace: 'nowrap',
                            },
                            ml: 1,
                          }}
                        />
                      )}
                      <TextField
                        variant="outlined"
                        size="small"
                        autoComplete='off'
                        value={searchTerm}
                        placeholder={"Search"}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            handleSearchData(e);
                          }
                        }}
                        fullWidth
                        sx={{
                          width: '70%',
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '15px',
                            bgcolor: 'white',
                          },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
              <Box>
                <>
                  {selectedTable === 'Map' ? (
                    <MapComponentV2
                      integrations={integrations}
                      setIntegrations={setIntegrations}
                      setSnackbarAlert={setSnackbarAlert}
                      setSnackbarOpen={setSnackbarOpen}
                      setSnackbarMessage={setSnackbarMessage}
                      filteredIntegrations={filteredIntegrationRows}
                    />
                  ) : (selectedTable === 'Orders' && selectedSubView === 'Header') ? (
                    <OrdersV2
                      rows={orderRows}
                      setRows={setOrderRows}
                      integrations={integrations}
                      selectedSubView={selectedSubView}
                      setSelectedSubView={setSelectedSubView}
                      setSnackbarAlert={setSnackbarAlert}
                      setSnackbarOpen={setSnackbarOpen}
                      setSnackbarMessage={setSnackbarMessage}
                      loading={loading}
                    />
                  ) : (selectedTable === 'Orders' && selectedSubView === 'Itemized') ? (
                    <OrderDetailsV2
                      rows={inventoryRows}
                      loading={loading}
                      selectedSubView={selectedSubView}
                      setSelectedSubView={setSelectedSubView}
                    />
                  ) : selectedTable === 'Documents' ? (
                    <DocumentsV2
                      rows={documentRows}
                      setRows={setDocumentRows}
                      integrations={integrations}
                      setSnackbarAlert={setSnackbarAlert}
                      setSnackbarOpen={setSnackbarOpen}
                      setSnackbarMessage={setSnackbarMessage}
                      loading={loading}
                    />
                  ) : null}
                </>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <CreatePartnerDialog
        dialogOpen={dialogOpen}
        handleClose={handleClose}
        integrationTypes={integrationTypes.filter(integration => integration.category !== 'HQ')}
        setIntegrations={setIntegrations}
        setSnackbarOpen={setSnackbarOpen}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbarAlert={setSnackbarAlert}
      />
      {
        partnerOpen && (
          <IntegrationDialogViewV2
            open={partnerOpen}
            onClose={() => setPartnerOpen(false)}
            integration={selectedIntegration}
            setIntegration={setSelectedIntegration}
            integrations={integrations}
            setIntegrations={setIntegrations}
            setSnackbarOpen={setSnackbarOpen}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarAlert={setSnackbarAlert}
          />
        )
      }
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography variant="body2" sx={{ mb: 2 }}>
            Are you sure you want to delete this partner?
          </Typography>
          <Typography variant="body2">
            <b>It will delete all orders and documents associated with this account and cannot be undone.</b>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeletePartner} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <SettingsDialog open={settingsDialogOpen} onClose={() => setSettingsDialogOpen(false)} />
      <SnackbarAlert
        snackbarOpen={snackbarOpen}
        handleSnackbarClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarAlert}
      />
    </Box >
  );
};

export default EmailClient;
