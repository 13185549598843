import React from 'react';
import { Dialog, DialogContent, Grid, Button, Box, Divider } from '@mui/material';
// import { getOrg } from '../api/users.js';

const SettingsDialog = ({ open, onClose }) => {

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogContent sx={{ height: '45vh', maxHeight: '45vh', display: 'flex', flexDirection: 'column' }}>
        <Grid container sx={{ height: '100%' }}>
          <Grid item xs={3}>
            <Box
              display="flex"
              flexDirection="column"
              gap={2}
              padding={1}
              height="100%"
            >
              <Button variant="text" fullWidth>
                User Management
              </Button>
              <Button variant="text" fullWidth>
                Feedback
              </Button>
            </Box>
          </Grid>
          <Divider orientation="vertical" flexItem sx={{ height: 'auto' }} />
          <Grid item xs={9}>
            <Box padding={2} height="100%">
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default SettingsDialog;
