import React, { useState, useEffect } from 'react';
import { GoogleMap, LoadScript, Marker, Data, MarkerClusterer } from '@react-google-maps/api';
import { fetchInventoryAggregatesV2 } from '../api/inventoryV2';
import { Box, Typography, Divider } from '@mui/material';
import hq from '../assets/hq2.svg';
import mfg from '../assets/factory2.svg';
import warehouse from '../assets/warehouse2.svg';
import supplier from '../assets/logistics2.svg';
import cluster from '../assets/cluster2.svg';
import pin from '../assets/pin.svg';
import cert from '../assets/certificate.svg';
import countries_outline from '../assets/countries.geojson';
import IntegrationDialogViewV2 from './IntegrationDialogViewV2';

const options = {
  mapId: process.env.REACT_APP_MAP_ID_V2,
  fullscreenControl: false,
  streetViewControl: false,
  mapTypeControl: false,
  zoomControl: false,
  minZoom: 2.1,
  scaleControl: true,
  restriction: {
    latLngBounds: {
      north: 85.00000,
      south: -85.00000,
      east: 180,
      west: -180
    },
    strictBounds: true,
  },
  rotateControl: false,
  trafficLayer: false,
  transitLayer: false,
  disableDefaultUI: true,
};

const MapComponentV2 = ({
  integrations,
  setIntegrations,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarAlert,
  filteredIntegrations,
}) => {
  const [selectedIntegration, setSelectedIntegration] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [inventoryAggregates, setInventoryAggregates] = useState([]);
  const [mapCenter, setMapCenter] = useState({ lat: 38.716, lng: -98.356 });

  useEffect(() => {
    const fetchInventoryData = async () => {
      const data = await fetchInventoryAggregatesV2();
      setInventoryAggregates(data);
    };

    fetchInventoryData();
  }, []);


  const handleMarkerClick = (integration) => {
    setSelectedIntegration(integration);
    setMapCenter({ lat: integration.coordinates.lat, lng: integration.coordinates.long });
    setDialogOpen(true);
  };

  const handleMapLoad = () => {
    setMapLoaded(true);
  };

  return (
    <>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: '83vh',
        maxHeight: '83vh',
      }}>
        <LoadScript
          googleMapsApiKey={process.env.REACT_APP_MAP_API}
          onLoad={handleMapLoad}
          onError={(error) => console.error('Error loading Google Maps script:', error)}
        >
          {mapLoaded && (
            <div style={{ position: 'relative', width: '100%', height: '100%' }}>
              {inventoryAggregates.length > 0 && (
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: 55,
                    right: 5,
                    borderRadius: '15px',
                    padding: '1rem',
                    backgroundColor: 'grey.100',
                    display: 'flex',
                    alignItems: 'left',
                    justifyContent: 'left',
                    flexDirection: 'column',
                    zIndex: 10,
                    width: '300px',
                    border: '1px solid black',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: '5px' }}>
                    <Typography sx={{ color: 'grey.800', fontSize: '1rem', fontWeight: 'bold' }}>Total Inventory by Partner</Typography>
                  </Box>
                  <Divider sx={{ bgcolor: 'grey.500' }} />
                  <Box
                    sx={{
                      padding: '0.5rem',
                      maxHeight: '55vh',
                      overflow: 'auto',
                      '&::-webkit-scrollbar': {
                        background: 'transparent',
                        width: 5,
                      },
                      '&::-webkit-scrollbar-thumb': {
                        background: 'grey',
                        borderRadius: 5
                      }
                    }}
                  >
                    {inventoryAggregates.map((row, index) => (
                      <div key={index} style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Typography sx={{ color: 'black' }}>{row.name}</Typography>
                        <Typography sx={{ color: 'black' }}>{row.qty.toLocaleString()}</Typography>
                      </div>
                    ))}
                  </Box>
                </Box>
              )}
              <GoogleMap
                mapContainerStyle={{
                  width: '100%',
                  height: '100%',
                }}
                center={mapCenter}
                zoom={5}
                options={options}
              >
                <MarkerClusterer
                  options={{
                    styles: [
                      {
                        url: cluster,
                        textColor: 'white',
                        height: 50,
                        width: 50,
                        fontFamily: 'MontHeavy',
                        textSize: 18,
                      },
                    ],
                    calculator: (markers) => {
                      const count = markers.length;
                      return {
                        text: `${count}`,
                        index: count < 10 ? 0 : count < 100 ? 1 : 2,
                      };
                    },
                  }}
                >
                  {(clusterer) => (
                    <div>
                      {integrations
                        .filter(
                          (integration) =>
                            integration.coordinates &&
                            integration.coordinates.lat &&
                            integration.coordinates.long &&
                            (filteredIntegrations.length === 0 || filteredIntegrations.includes(integration.id))
                        )
                        .map((integration) => (
                          <Marker
                            key={integration.id}
                            position={{ lat: integration.coordinates.lat, lng: integration.coordinates.long }}
                            clusterer={clusterer}
                            label={{
                              text: integration.name,
                              color: '#474747',
                              fontSize: '12px',
                              className: 'marker-label',
                              fontFamily: 'MontHeavy',
                            }}
                            icon={{
                              url:
                                integration.integration_type.category === 'HQ'
                                  ? hq
                                  : integration.integration_type.category === '3PL'
                                    ? warehouse
                                    : integration.integration_type.category === 'Supplier'
                                      ? supplier
                                      : integration.integration_type.type === 'Certifying Authority'
                                        ? cert
                                        : integration.integration_type.type === 'Other'
                                          ? pin
                                          : mfg,
                              scaledSize: new window.google.maps.Size(45, 45),
                              anchor: new window.google.maps.Point(25, 25),
                              labelOrigin: new window.google.maps.Point(25, -5),
                            }}
                            onClick={() => handleMarkerClick(integration)}
                          />
                        ))}
                    </div>
                  )}
                </MarkerClusterer>
                <Data
                  key="countries_layer"
                  onLoad={(dataLayer) => {
                    dataLayer.loadGeoJson(countries_outline);
                    dataLayer.setStyle({
                      strokeColor: '#757575',
                      strokeWeight: 1,
                      fillOpacity: 0
                    });
                  }}
                />
              </GoogleMap>
            </div>
          )}
        </LoadScript>
      </Box>
      {selectedIntegration && (
        <IntegrationDialogViewV2
          open={dialogOpen}
          onClose={() => {
            setSelectedIntegration(null);
            setDialogOpen(false)
          }}
          integration={selectedIntegration}
          setIntegration={setSelectedIntegration}
          integrations={integrations}
          setIntegrations={setIntegrations}
          setSnackbarOpen={setSnackbarOpen}
          setSnackbarMessage={setSnackbarMessage}
          setSnackbarAlert={setSnackbarAlert}
        />
      )}
    </>
  );
};

export default MapComponentV2;
