import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  TextField,
  LinearProgress
} from '@mui/material';
import { createIntegration } from '../api/integrations';
import CustomTextField from './CustomTextField';
import LabeledDropdown from './LabeledDropdown';
import { axiosInstance, checkToken } from '../api/utils';
import { refreshDocuments } from '../api/documents';
import SearchIcon from '@mui/icons-material/Search';

const CreatePartnerDialog = ({
  dialogOpen,
  handleClose,
  integrationTypes,
  setIntegrations,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarAlert,
}) => {
  const [name, setName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [type, setType] = useState('');
  const [typeName, setTypeName] = useState('');
  const [locationSearch, setLocationSearch] = React.useState('');
  const [streetAddress, setStreetAddress] = React.useState('');
  const [coordinates, setCoordinates] = React.useState(null);
  const [searchingLocation, setSearchingLocation] = React.useState(false);

  const handleTypeChange = (event) => {
    const selectedTypeId = event.target.value;
    const selectedType = integrationTypes.find(type => type.id === selectedTypeId);
    setType(selectedTypeId);
    setTypeName(selectedType.type)
  };

  const handleRefreshDocuments = async (integration_id) => {
    try {
      await refreshDocuments(integration_id);
      setSnackbarOpen(true);
      setSnackbarMessage("Documents refresh triggered. If your email is linked, documents should populate momentarily.");
      setSnackbarAlert("success");
    } catch (error) {
      console.error('Error:', error);
      setSnackbarOpen(true);
      setSnackbarMessage("Unable to sync documents automatically. Please link your email and refresh manually.");
      setSnackbarAlert("error");
    }
  };


  const handleSubmit = async () => {
    try {
      const newIntegration = {
        integration_type_id: type,
        name,
        contact_name: '',
        is_brand_operated: false,
        is_3pl: null,
        is_supplier: false,
        minimum_order_qty: null,
        minimum_order_units: '',
        default_lead_time: null,
        contact_title: '',
        contact_email: contactEmail,
        street_address: streetAddress,
        billing_address: '',
        config: {},
        coordinates: coordinates || {}
      };

      const integrationSuccess = await createIntegration(newIntegration);
      setIntegrations((prevIntegrations) => [...prevIntegrations, integrationSuccess]);
      setName('');
      setContactEmail('');
      setType('');
      setTypeName('');
      setStreetAddress('');
      setLocationSearch('');
      setCoordinates(null);
      handleRefreshDocuments(integrationSuccess.id);
      handleClose();
    } catch (error) {
      console.error('Error creating integration:', error);
      setSnackbarOpen(true);
      setSnackbarAlert("error");
      setSnackbarMessage(error.response.data.detail);
    }
  };

  const handleLocationLookup = async () => {
    setSearchingLocation(true);
    try {
      const token = await checkToken();
      const response = await axiosInstance(token).post('/location_lookup/', { location: locationSearch });
      setStreetAddress(response.data.body[0].formatted_address);
      setCoordinates({
        lat: response.data.body[0].geometry.location.lat,
        long: response.data.body[0].geometry.location.lng
      });
    } catch (error) {
      console.error('Error fetching location data:', error);
    } finally {
      setSearchingLocation(false);
    }
  };


  const handleKeyPress = async (e) => {
    if (e.key === 'Enter') {
      await handleLocationLookup();
    }
  };


  return (
    <Dialog
      open={dialogOpen}
      onClose={handleClose}
      maxWidth="sm"
      PaperProps={{
        style: {
          backgroundColor: '#f2f2f2',
        },
      }}
    >
      <DialogTitle>Add Partner</DialogTitle>
      <DialogContent>
        <LabeledDropdown
          label="Type"
          value={type}
          sx={{ my: '0.25rem' }}
          options={integrationTypes.map(row => ({ value: row.id, label: row.type }))}
          onChange={handleTypeChange}
        />
        <CustomTextField
          label={"Partner Name"}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <CustomTextField
          label="Contact Email"
          disabled={typeName === 'HQ'}
          value={contactEmail}
          onChange={(e) => setContactEmail(e.target.value)}
        />
        <Box sx={{ display: 'flex', alignItems: 'center', mt: '0.25rem' }}>
          <TextField
            label="Search Partner Address (optional)"
            autoComplete='off'
            size="small"
            fullWidth
            sx={{ mr: '5px' }}
            onChange={(e) => setLocationSearch(e.target.value)}
            onKeyPress={handleKeyPress}
          />
          {searchingLocation ? (
            <LinearProgress sx={{ width: '30%' }} />
          ) : (
            <Button
              variant="outlined"
              color="primary"
              startIcon={<SearchIcon />}
              fullWidth
              sx={{ width: '30%' }}
              onClick={async () => await handleLocationLookup()}
            >
              Search
            </Button>
          )}
        </Box>
        {streetAddress && (
          <Typography variant="body2" sx={{ my: '0.5rem' }}>
            Formatted Address: <b>{streetAddress}</b>
          </Typography>
        )}
        <Typography variant="body2" sx={{ mt: '0.5rem' }}>
          If your email is linked, trinity will automatically sync documents from <b>all emails associated with this partner's domain.</b>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        <Button onClick={handleSubmit} color="primary" variant='contained'>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreatePartnerDialog;
