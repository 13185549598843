import React from 'react';
import {
  Box,
  IconButton,
  Select,
  MenuItem,
  Tooltip
} from '@mui/material';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { format } from 'date-fns';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import { deleteOrderV2, updateOrderV2 } from '../../api/ordersV2.js'
import OrderDetailViewer from '../OrderDetailViewer';
import ToggleOrdersViewV2 from '../../components/ToggleOrdersViewV2.js';

export default function OrdersV2({
  rows,
  setRows,
  integrations,
  selectedSubView,
  setSelectedSubView,
  setSnackbarAlert,
  setSnackbarOpen,
  setSnackbarMessage,
  loading,
  height = "83vh",
}) {
  const [selectedOrder, setSelectedOrder] = React.useState("");

  const handleDeleteOrder = async (id) => {
    try {
      await deleteOrderV2(id);
      const updatedRows = rows.filter((row) => row.id !== id);
      setRows(updatedRows);
      setSnackbarOpen(true);
      setSnackbarMessage('Order deleted successfully.');
      setSnackbarAlert('success');
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage('Error deleting order.');
      setSnackbarAlert('error');
    }
  };

  const handleProcessRowUpdate = async (updatedRow, oldRow) => {
    await updateOrderV2(updatedRow.id, updatedRow);
    const updatedRows = rows.map((row) => (row.id === updatedRow.id ? updatedRow : row));
    setRows(updatedRows);
    return updatedRow;
  };


  const columns = [
    { field: 'order_name', headerName: 'Order', flex: 1.25, editable: true },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => {
        const handleChange = (event) => {
          const updatedStatus = event.target.value;
          const updatedRow = { ...params.row, status: updatedStatus };
          handleProcessRowUpdate(updatedRow, params.row);
        };
        return (
          <>
            <Select
              labelId="status-select-label"
              value={params.value}
              label="Status"
              variant='standard'
              fullWidth
              onChange={handleChange}
              sx={{ fontFamily: 'inherit', fontSize: 'inherit' }}
            >
              <MenuItem value="PO Sent">PO Sent</MenuItem>
              <MenuItem value="In Transit">In Transit</MenuItem>
              <MenuItem value="Delivered">Delivered</MenuItem>
            </Select>
          </>
        )
      }
    },
    {
      field: 'integration_id', headerName: 'Partner', flex: 1,
      renderCell: (params) => {
        const value = integrations.find((item) => item.id === params.value);

        const handleChange = (event) => {
          const updatedIntegrationId = event.target.value;
          const updatedRow = { ...params.row, integration_id: updatedIntegrationId };
          handleProcessRowUpdate(updatedRow, params.row);
        };
        return (
          <>
            <Select
              labelId="status-select-label"
              value={value.id}
              label="Status"
              variant='standard'
              fullWidth
              onChange={handleChange}
              sx={{ fontFamily: 'inherit', fontSize: 'inherit' }}
            >
              {integrations.map((integration) => (
                <MenuItem key={integration.id} value={integration.id}>
                  {integration.name}
                </MenuItem>
              ))}
            </Select>
          </>
        )
      },
    },
    {
      field: 'integration_id_to', headerName: 'Partner To', flex: 1,
      renderCell: (params) => {
        const value = integrations.find((item) => item.id === params.value);

        const handleChange = (event) => {
          const updatedIntegrationId = event.target.value;
          const updatedRow = { ...params.row, integration_id_to: updatedIntegrationId };
          handleProcessRowUpdate(updatedRow, params.row);
        };
        return (
          <>
            <Select
              labelId="status-select-label"
              value={value?.id}
              label="Status"
              variant='standard'
              fullWidth
              onChange={handleChange}
              sx={{ fontFamily: 'inherit', fontSize: 'inherit' }}
            >
              {integrations.map((integration) => (
                <MenuItem key={integration.id} value={integration.id}>
                  {integration.name}
                </MenuItem>
              ))}
            </Select>
          </>
        )
      },
    },
    {
      field: 'total_freight', headerName: 'Freight Cost', flex: 1, editable: true,
      renderCell: (params) => {
        const value = params.value;
        if (!value) {
          return '';
        }
        return `$${value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
      },
    },
    {
      field: 'header_detail',
      headerName: 'Total Cost',
      flex: 1,
      renderCell: (params) => {
        const value = params?.row?.header_detail;
        let totalCost = 0;

        if (Array.isArray(value)) {
          totalCost = value.reduce((sum, item) => {
            const itemPrice = parseFloat(item?.item_price) || 0;
            const orderQty = parseFloat(item?.order_qty) || 0;
            return sum + (itemPrice * orderQty);
          }, 0);
        }

        const freightCost = parseFloat(params?.row?.total_freight) || 0;
        totalCost += freightCost;

        if (totalCost === 0) {
          return '';
        }

        return totalCost.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
      },
    },
    {
      field: 'order_date', headerName: 'Order Date', width: 140,
      renderCell: (params) => {
        const value = params.value;
        if (!value) {
          return '';
        }
        const date = new Date(value);
        return format(date, 'MM/dd/yy');
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 120,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <>
          <Tooltip title="View order details">
            <IconButton
              key={`view-icon-${params.row.id}`}
              onClick={() => setSelectedOrder(params.row)}
            >
              <ViewInArIcon color='info' />
            </IconButton>
          </Tooltip>
          <IconButton
            key={`delete-icon-${params.row.id}`}
            onClick={() => handleDeleteOrder(params.row.id)}
          >
            <DeleteOutlineOutlinedIcon color='error' />
          </IconButton>
        </>
      )
    }
  ];

  const Toolbar = () => {
    return (
      <GridToolbarContainer >
        <ToggleOrdersViewV2
          currentSubView={selectedSubView}
          setCurrentSubView={setSelectedSubView}
        />
      </GridToolbarContainer>
    );
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      bgcolor: 'white',
      p: selectedOrder ? 2 : 0,
      height: height,
      borderRadius: '10px',
      border: selectedOrder ? '1px solid grey' : undefined,
      boxShadow: selectedOrder ? '0px 4px 4px rgba(0, 0, 0, 0.25)' : undefined,
      maxHeight: height,
      width: '100%',
    }}>
      {!selectedOrder ? (
        <Box sx={{ width: '100%' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            density="compact"
            loading={loading}
            disableRowSelectionOnClicks
            slots={{ toolbar: Toolbar }}
            processRowUpdate={handleProcessRowUpdate}
            hideFooter
          />
        </Box>
      ) : (
        <OrderDetailViewer
          order={selectedOrder}
          setOrder={setSelectedOrder}
          setOrderRows={setRows}
        />
      )}
    </Box>
  );
}
