// InventorySearchAutocomplete.js

import { useState, useEffect } from 'react';
import { Autocomplete, TextField, CircularProgress, InputAdornment } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { fetchOrderDetailsV2 } from '../api/ordersV2';

function InventorySearchAutocomplete({ onSelect }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (searchTerm) {
      setLoading(true);

      fetchOrderDetailsV2(searchTerm).then((response) => {
        const uniqueItems = Array.from(
          new Set(response.map((item) => item.item_name))
        );

        setOptions(uniqueItems);
        setLoading(false);
      });
    } else {
      setOptions([]);
    }
  }, [searchTerm]);

  return (
    <Autocomplete
      freeSolo
      options={options}
      loading={loading}
      fullWidth
      size="small"
      onInputChange={(event, value) => setSearchTerm(value)}
      onChange={(event, newValue) => onSelect(newValue)}
      sx={{
        width: '30%',
        '& .MuiOutlinedInput-root': {
          borderRadius: '10px',
        },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Add Existing Item"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <AddIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

export default InventorySearchAutocomplete;
