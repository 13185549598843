import {
  Box,
  IconButton,
  Tooltip
} from '@mui/material';

const StyledIconButton = ({ icon, onClick, tooltip }) => {
  const button = (
    <Box sx={{ display: 'flex', alignItems: 'center', ml: '0.5rem' }}>
      <IconButton
        onClick={onClick}
        sx={{ height: '2.5rem', color: 'white', '&:hover': { bgcolor: 'rgba(255, 255, 255, 0.08)' } }}
      >
        {icon}
      </IconButton>
    </Box>
  );

  return tooltip ? (
    <Tooltip title={tooltip} placement="left">
      {button}
    </Tooltip>
  ) : (
    button
  );
};

export default StyledIconButton;
