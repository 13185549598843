import * as React from 'react';
import ViewListIcon from '@mui/icons-material/ViewList';
import OilBarrelIcon from '@mui/icons-material/OilBarrel';
import ToggleButton from '@mui/material/ToggleButton';
import WidgetsIcon from '@mui/icons-material/Widgets';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import Typography from '@mui/material/Typography';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';

export default function ToggleDetailsViewV2({ integrationType, currentView, setCurrentView }) {
  const buttonStyles = {
    color: '#2b2b2b',
    backgroundColor: 'transparent',
    border: '1px solid black',
    borderRadius: '10px',
    '&.Mui-selected': {
      backgroundColor: '#2b2b2b',
      color: 'white',
      '&:hover': {
        backgroundColor: '#2b2b2b',
      },
    },
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
  };

  const handleViewChange = (event, newValue) => {
    if (newValue !== null) {
      setCurrentView(newValue);
    }
  };

  return (
    <>
      {['Supplier'].includes(integrationType) && (
        <ToggleButtonGroup
          value={currentView}
          exclusive
          onChange={handleViewChange}
          aria-label="entity-view"
          size='small'
        >
          <ToggleButton value="documents" aria-label="documents" sx={buttonStyles}>
            <DocumentScannerIcon />
            <Typography variant="body2" sx={{ mx: 1, textTransform: 'none' }}>Documents</Typography>
          </ToggleButton>
          <ToggleButton value="orders" aria-label="centered" sx={buttonStyles}>
            <ViewListIcon />
            <Typography variant="body2" sx={{ mx: 1, textTransform: 'none' }}>Orders</Typography>
          </ToggleButton>
          <ToggleButton value="material" aria-label="materials" sx={buttonStyles}>
            <OilBarrelIcon />
            <Typography variant="body2" sx={{ mx: 1, textTransform: 'none' }}>Materials</Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      )}

      {['3PL'].includes(integrationType) && (
        <ToggleButtonGroup
          value={currentView}
          exclusive
          onChange={handleViewChange}
          aria-label="entity-view"
          size='small'
        >
          <ToggleButton value="documents" aria-label="documents" sx={buttonStyles}>
            <DocumentScannerIcon />
            <Typography variant="body2" sx={{ mx: 1, textTransform: 'none' }}>Documents</Typography>
          </ToggleButton>
          <ToggleButton value="incoming" aria-label="incoming" sx={buttonStyles}>
            <LocalShippingIcon />
            <Typography variant="body2" sx={{ mx: 1, textTransform: 'none' }}>Inbound Orders</Typography>
          </ToggleButton>
          <ToggleButton value="product" aria-label="centered" sx={buttonStyles}>
            <WidgetsIcon />
            <Typography variant="body2" sx={{ mx: 1, textTransform: 'none' }}>Products</Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      )}

      {['Distributor', 'Customer'].includes(integrationType) && (
        <ToggleButtonGroup
          value={currentView}
          exclusive
          onChange={handleViewChange}
          aria-label="entity-view"
          size='small'
        >
          <ToggleButton value="documents" aria-label="documents" sx={buttonStyles}>
            <DocumentScannerIcon />
            <Typography variant="body2" sx={{ mx: 1, textTransform: 'none' }}>Documents</Typography>
          </ToggleButton>
          <ToggleButton value="orders" aria-label="orders" sx={buttonStyles}>
            <ViewListIcon />
            <Typography variant="body2" sx={{ mx: 1, textTransform: 'none' }}>Orders</Typography>
          </ToggleButton>
          <ToggleButton value="incoming" aria-label="incoming" sx={buttonStyles}>
            <LocalShippingIcon />
            <Typography variant="body2" sx={{ mx: 1, textTransform: 'none' }}>Inbound Orders</Typography>
          </ToggleButton>
          <ToggleButton value="product" aria-label="centered" sx={buttonStyles}>
            <WidgetsIcon />
            <Typography variant="body2" sx={{ mx: 1, textTransform: 'none' }}>Products</Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      )}

      {['Manufacturer'].includes(integrationType) && (
        <ToggleButtonGroup
          value={currentView}
          exclusive
          onChange={handleViewChange}
          aria-label="entity-view"
          size='small'
        >
          <ToggleButton value="documents" aria-label="documents" sx={buttonStyles}>
            <DocumentScannerIcon />
            <Typography variant="body2" sx={{ mx: 1, textTransform: 'none' }}>Documents</Typography>
          </ToggleButton>
          <ToggleButton value="orders" aria-label="orders" sx={buttonStyles}>
            <ViewListIcon />
            <Typography variant="body2" sx={{ mx: 1, textTransform: 'none' }}>Orders</Typography>
          </ToggleButton>
          <ToggleButton value="product" aria-label="centered" sx={buttonStyles}>
            <WidgetsIcon />
            <Typography variant="body2" sx={{ mx: 1, textTransform: 'none' }}>Products</Typography>
          </ToggleButton>
          <ToggleButton value="material" aria-label="materials" sx={buttonStyles}>
            <OilBarrelIcon />
            <Typography variant="body2" sx={{ mx: 1, textTransform: 'none' }}>Materials</Typography>
          </ToggleButton>
          <ToggleButton value="incoming" aria-label="incoming" sx={buttonStyles}>
            <LocalShippingIcon />
            <Typography variant="body2" sx={{ mx: 1, textTransform: 'none' }}>Inbound Orders</Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      )}
      {['HQ', 'Other', 'Certifying Authority'].includes(integrationType) && (
        <ToggleButtonGroup
          value={currentView}
          exclusive
          onChange={handleViewChange}
          aria-label="entity-view"
          size='small'
        >
          <ToggleButton value="documents" aria-label="documents" sx={buttonStyles}>
            <DocumentScannerIcon />
            <Typography variant="body2" sx={{ mx: 1, textTransform: 'none' }}>Documents</Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      )}
    </>
  );
}
