import { axiosInstance, checkToken } from './utils.js'


const fetchInventoryV2 = async (integration_id = null, item_type = null, to_flag = null) => {
  const params = new URLSearchParams();
  if (integration_id) params.append('integration_id', integration_id);
  if (item_type) params.append('item_type', item_type);
  if (to_flag) params.append('to_flag', to_flag);
  const queryString = params.toString();
  const url = `/v2/inventory/` + (queryString ? `?${queryString}` : '');
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get(url);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

const fetchInventoryAggregatesV2 = async (product_level = null) => {
  const params = new URLSearchParams();
  if (product_level) params.append('product_level', product_level);
  const queryString = params.toString();
  const url = `/v2/aggregates/inventory/` + (queryString ? `?${queryString}` : '');
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get(url);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};


const fetchSalesAggregatesV2 = async (localTZ) => {
  const params = new URLSearchParams();
  if (localTZ) params.append('localTZ', localTZ);
  const queryString = params.toString();
  const url = `/v2/aggregates/sales/` + (queryString ? `?${queryString}` : '');
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).get(url);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};


const createInventoryV2 = async (inventory) => {
  const url = '/v2/inventory/';
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).post(url, inventory);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};


const updateInventoryV2 = async (inventory) => {
  const url = `/v2/inventory/`;
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).patch(url, inventory);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};



const deleteInventoryV2 = async (id) => {
  const url = '/v2/inventory/';
  try {
    const token = await checkToken();
    const response = await axiosInstance(token).delete(`${url}${id}`);
    return response.data;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};


export {
  fetchInventoryV2,
  fetchInventoryAggregatesV2,
  fetchSalesAggregatesV2,
  createInventoryV2,
  updateInventoryV2,
  deleteInventoryV2
};
